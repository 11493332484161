import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import Button from 'components/button/Base';
import { useDispatch } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { object, ref, string } from 'yup';
import { useFormik } from 'formik';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from 'components/input';
import { useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/useMediaQueries';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { enqueueSnackbar } from 'notifier/actions';
import { useUpdateIncomePasswordMutation } from 'services/merchant';

const StaffCredentialSchema = object({
    oldPassword: string().min(8, 'Min is 8 character').required('This filed is required'),
    newPassword: string().min(8, 'Min is 8 character').required('This filed is required'),
    confirmPassword: string()
        .min(8, 'Min is 8 character')
        .oneOf([ref('newPassword'), null], 'Password not matched')
        .required('This filed is required')
});

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 400
    },
    formContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    fullWidth: {
        width: '100%'
    },
    active: {
        background: theme.colors.blue,
        color: theme.palette.common.white,
        fontWeight: 'bold'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));
function ChangeIncomePassword({ onClose }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isMobile } = useMediaQueries();
    const dispatch = useDispatch();

    const [offOldPassword, setOffOldPassword] = useState(true);
    const [offNewPassword, setOffNewPassword] = useState(true);
    const [offConfirmPassword, setOffConfirmPassword] = useState(true);

    const { mutate } = useUpdateIncomePasswordMutation({
        onSuccess: () => {
            dispatch(
                enqueueSnackbar({
                    message: `Change income password successfully`,
                    type: 'success'
                })
            );
            onClose && onClose();
        },
        onError: () => {
            console.log('update income password failed');
        }
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: StaffCredentialSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);
            const body = {
                newPassword: values.newPassword,
                oldPassword: values.oldPassword
            };

            mutate(body);

            setStatus({ error: null });
            setSubmitting(false);
            console.log('values', values);
        }
    });

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const { handleSubmit, isSubmitting, isValid, values, errors, touched, handleChange, handleBlur } = formik;

    return (
        <form onClick={stopPropagation} className={classes.form}>
            <DialogTitle>
                <Typography variant="h5">Change password</Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container onClick={stopPropagation} className={classes.formContent} direction="column">
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="center">
                            <Grid item className={classes.fullWidth}>
                                <InputBase
                                    type={offOldPassword ? 'password' : 'text'}
                                    label={t(`oldPassword`)}
                                    name="oldPassword"
                                    value={values.oldPassword}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.oldPassword && touched.oldPassword}
                                    errorText={errors.oldPassword}
                                    placeholder="old password"
                                    autoFocus={true}
                                    endAdornment={
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setOffOldPassword(!offOldPassword)}
                                        >
                                            {offOldPassword ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    }
                                />
                            </Grid>

                            <Grid item className={classes.fullWidth}>
                                <InputBase
                                    type={offNewPassword ? 'password' : 'text'}
                                    label={t(`newPassword`)}
                                    name="newPassword"
                                    value={values.newPassword}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.newPassword && touched.newPassword}
                                    errorText={errors.newPassword}
                                    placeholder="new password"
                                    endAdornment={
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setOffNewPassword(!offNewPassword)}
                                        >
                                            {offNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <InputBase
                                    type={offConfirmPassword ? 'password' : 'text'}
                                    label={t(`confirmPassword`)}
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.confirmPassword && touched.confirmPassword}
                                    errorText={errors.confirmPassword}
                                    placeholder="confirm password"
                                    endAdornment={
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setOffConfirmPassword(!offConfirmPassword)}
                                        >
                                            {offConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<CloseIcon />} onClick={onClose} fullWidth={isMobile} variant="outlined">
                    {t(`close`)}
                </Button>
                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                    <Button
                        startIcon={<SaveIcon />}
                        disabled={isSubmitting || !isValid}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {t(`save`)}
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </form>
    );
}

export default ChangeIncomePassword;
