import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import CalenderSVG from 'assets/images/svg/CalenderSVG';
import ServicesSVG from 'assets/images/svg/ServicesSVG';
import SmsSVG from 'assets/images/svg/SmsSVG';
// import MerchantSettingSVG from 'assets/images/svg/MerchantSettingSVG';
import AnalyzeSVG from 'assets/images/svg/AnalyzeSVG';
// import PricingSettingSVG from 'assets/images/svg/PricingSettingSVG';

import ClientSVG from 'assets/images/svg/ClientSVG';
import StaffSVG from 'assets/images/svg/StaffSVG';
import HambergerMenuSVG from 'assets/images/svg/HambergerMenuSVG';
import { Link, useLocation } from 'react-router-dom';
import { FOOTER_HEIGHT, MERCHANT_PERMISSIONS } from 'const';
import VoucherSVG from 'assets/images/svg/VoucherSVG';
import { isAuthorized } from 'utils/app/auth';
import { useMerchantPermissions } from 'hooks/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        height: FOOTER_HEIGHT,
        '& a': {
            padding: `0 !important`,
            minWidth: 50
        },
        '& svg': {
            width: 20,
            height: 20
        },
        '& .Mui-selected': {
            '&  path': {
                fill: `${theme.palette.primary.main} !important`
            }
        }
    }
}));

export default function LabelBottomNavigation() {
    const classes = useStyles();
    const location = useLocation();
    const permissions = useMerchantPermissions();
    const routes = [
        { id: 'calendar', icon: <CalenderSVG />, path: '/calendar', permissions: [] },
        { id: 'services', icon: <ServicesSVG />, path: '/services', permissions: [] },
        { id: 'clients', icon: <ClientSVG />, path: '/clients' },
        { id: 'staff', icon: <StaffSVG />, path: '/staff', permissions: [] },
        { id: 'nav_sms', icon: <SmsSVG />, path: '/sms', permissions: [] },
        { id: 'vouchers', icon: <VoucherSVG />, path: '/gifts', permissions: [MERCHANT_PERMISSIONS.GIFT] },
        // { id: 'setting', icon: <MerchantSettingSVG />, path: '/setting', permissions: [] },
        // {
        //     id: 'Hide Price',
        //     icon: <PricingSettingSVG />,
        //     path: '/prices',
        //     permissions: [
        //         MERCHANT_PERMISSIONS.INTERNAL,
        //         MERCHANT_PERMISSIONS.INTERNAL_BASIC,
        //         MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
        //     ],
        //     title: 'Hide Price Dates'
        // },
        {
            id: 'Statistics',
            icon: <AnalyzeSVG />,
            path: '/analyzes',
            permissions: [MERCHANT_PERMISSIONS.INTERNAL, MERCHANT_PERMISSIONS.INTERNAL_BASIC],
            title: 'Statistics'
        },
        { id: 'profile', icon: <HambergerMenuSVG />, path: '/profile', permissions: [] }
    ];

    const [value, setValue] = React.useState('calendar');

    let patials = location?.pathname?.split('/');
    const selectedRoute = routes.find((route) => patials.includes(route.id));
    const selectedId = selectedRoute?.id;

    React.useEffect(() => {
        setValue(selectedId);
    }, [selectedId]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation value={value} onChange={handleChange} className={classes.root}>
            {routes?.map(
                (route) =>
                    isAuthorized({ userPermission: permissions, permissions: route?.permissions }) && (
                        <BottomNavigationAction
                            to={route.path}
                            key={route.id}
                            value={route.id}
                            icon={route.icon}
                            component={Link}
                        />
                    )
            )}
        </BottomNavigation>
    );
}
