import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { getFullName } from 'utils/naming';
import { getImageUrl } from 'utils/image';
import { getCurrentDateFormat } from 'utils/timing';

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 20
    },
    branding: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 20,
        marginBottom: 20
    },
    title: {
        fontSize: 13,
        lineHeight: 1.5
    },
    subtitle: {
        fontSize: 13,
        lineHeight: 1.5
    },
    textBold: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.5,
        fontFamily: 'Times-Roman'
    },
    text: {
        fontSize: 10,
        lineHeight: 1.5,
        fontFamily: 'Times-Roman'
    },
    logo: {
        maxWidth: 120,
        height: '100%'
    },
    header: {
        fontSize: 12,
        textAlign: 'center',
        color: 'grey'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    }
});

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

export function Invoice({ merchant, booking, staffOptions, serviceOptions, saleDate }) {
    const totalPrice = React.useMemo(() => {
        return booking?.bookingServices?.reduce((t, bkSv) => {
            return t + (bkSv?.paidPrice || 0);
        }, 0);
    }, [booking]);

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.branding}>
                    <View
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 20
                        }}
                    >
                        <Image style={styles.logo} src={getImageUrl(merchant?.logo)} />
                        <View
                            style={{
                                flex: 1
                            }}
                        >
                            <Text style={styles.subtitle}>{merchant?.title ?? '<merchant name>'}</Text>
                            <Text style={styles.text}>{merchant?.address ?? '<merchant address>'}</Text>
                            <Text style={styles.text}>
                                Tax Registration Number {merchant?.taxNumber ?? '<tax number>'}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.text}>{merchant?.phone ?? '<merchant phone>'}</Text>
                        <Text style={styles.text}>{booking?.mechant?.contactEmail ?? '<merchant email>'}</Text>
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        gap: 10,
                        marginBottom: 20
                    }}
                >
                    <Text style={styles.textBold}>BILL TO</Text>
                    <View>
                        <Text style={styles.text}>{getFullName(booking?.client) ?? '<client name>'}</Text>
                        <Text style={styles.text}>ON</Text>
                        <Text style={styles.text}>CANADA</Text>
                    </View>
                </View>

                <View
                    style={{
                        marginBottom: 10,
                        flexDirection: 'row',
                        gap: 1
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            gap: 10,
                            borderColor: 'black',
                            borderWidth: 1,
                            borderStyle: 'solid'
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 10
                            }}
                        >
                            <Text style={styles.textBold}>Invoice date: {getCurrentDateFormat(new Date())}</Text>
                            <Text style={styles.textBold}>Sale date: {saleDate}</Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'black',
                                paddingHorizontal: 10
                            }}
                        >
                            <Text
                                style={{
                                    ...styles.textBold,
                                    color: 'white'
                                }}
                            >
                                Amount
                            </Text>
                            <Text
                                style={{
                                    ...styles.text,
                                    fontSize: 14,
                                    color: 'white'
                                }}
                            >
                                {merchant?.currency}
                                {totalPrice}
                            </Text>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 10
                            }}
                        >
                            <Text style={styles.textBold}>Enclosed</Text>
                        </View>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={styles.text}>
                        Currency: <Text style={styles.textBold}>{merchant?.currency}</Text>
                    </Text>
                </View>
                <View
                    style={{
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid'
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            gap: 8,
                            paddingVertical: 5,
                            paddingHorizontal: 5
                        }}
                    >
                        <Text style={{ ...styles.text, width: 20 }} />
                        <Text style={{ ...styles.textBold, flex: 1, textTransform: 'uppercase' }}>SERVICE</Text>
                        <Text style={{ ...styles.text, width: 80 }}>DESCRIPTION</Text>
                        <Text style={{ ...styles.text, width: 80 }}>AMOUNT</Text>
                    </View>
                </View>
                {booking?.bookingServices?.map((bkSv, index) => {
                    const service = serviceOptions?.find((opt) => +opt?.id === +bkSv?.serviceId);
                    const staff = staffOptions?.find((opt) => +opt?.id === +bkSv?.staffId);

                    return (
                        <View
                            key={bkSv?.id}
                            style={{
                                borderColor: 'black',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderTop: 'none'
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    gap: 8,
                                    paddingVertical: 5,
                                    paddingHorizontal: 5
                                }}
                            >
                                <View style={{ width: 20 }}>
                                    <Text style={styles.text}>{index + 1}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={styles.text}>
                                        {service?.title} with {getFullName(staff)}
                                    </Text>
                                </View>
                                <Text style={{ ...styles.text, width: 80 }}>{service.depositRate}% Deposit</Text>

                                <Text style={{ ...styles.text, width: 80 }}>
                                    {merchant?.currency}
                                    {(bkSv?.paidPrice).toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    );
                })}

                <View
                    style={{
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        marginTop: 10
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.textBold, flex: 1, paddingHorizontal: 10, paddingVertical: 5 }}>
                            Notes
                        </Text>
                        <View
                            style={{
                                flex: 1,
                                paddingHorizontal: 10,
                                paddingVertical: 5,
                                flexDirection: 'column',
                                borderLeft: `1px solid black`
                            }}
                        >
                            <Text style={styles.text}>Subtotal</Text>
                            <View
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Text style={styles.text}>
                                    {merchant?.currency}
                                    {totalPrice}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View
                    style={{
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderBottom: 'none',
                        borderTop: 'none'
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.textBold, flex: 1, paddingHorizontal: 10, paddingVertical: 5 }}></Text>
                        <View
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHorizontal: 10,
                                paddingVertical: 5,
                                borderLeft: `1px solid black`,
                                borderBottom: `1px solid black`
                            }}
                        >
                            <View>
                                <Text style={styles.text}>Invoice total</Text>
                            </View>
                            <View
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Text style={styles.text}>
                                    {merchant?.currency}
                                    {totalPrice}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/* <View
                    style={{
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderBottom: 'none',
                        borderTop: 'none'
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.textBold, flex: 1, paddingHorizontal: 10, paddingVertical: 5 }}></Text>
                        <View
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHorizontal: 10,
                                paddingVertical: 5,
                                borderLeft: `1px solid black`,
                                borderBottom: `1px solid black`
                            }}
                        >
                            <View>
                                <Text style={styles.text}>Adjustment</Text>
                                <Text style={styles.text}>2024-07-23</Text>
                                <Text style={styles.text}>Payment made with POS In-Store</Text>
                            </View>
                            <View
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Text style={styles.text}>$56.50</Text>
                            </View>
                        </View>
                    </View>
                </View> */}

                <View
                    style={{
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderBottom: '1px solid black',
                        borderTop: 'none'
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.textBold, flex: 1, paddingHorizontal: 10, paddingVertical: 5 }}></Text>
                        <View
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHorizontal: 10,
                                paddingVertical: 5,
                                borderLeft: `1px solid black`,
                                borderBottom: `1px solid black`,
                                backgroundColor: 'black'
                            }}
                        >
                            <View>
                                <Text
                                    style={{
                                        ...styles.title,
                                        color: 'white'
                                    }}
                                >
                                    Amount due
                                </Text>
                            </View>
                            <View
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Text
                                    style={{
                                        ...styles.text,
                                        color: 'white',
                                        fontSize: 14
                                    }}
                                >
                                    ({merchant?.currency}
                                    {totalPrice})
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}
