import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { phoneFormatOptions } from 'data/phoneNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { addPlus } from 'utils/phone';
import { useHidePhone } from 'hooks/app';
import { Typography } from '@material-ui/core';
import { formatPhone } from 'components/format-phone';

const useStyles = makeStyles((theme) => ({
    root: {},

    input: {
        width: '100%',
        height: '100%',
        border: 'none',
        padding: 0,
        fontWeight: (props) => props?.bold && 600,
        color: '#333333',
        fontSize: 14,
        fontStyle: 'normal',
        background: 'transparent',
        '&:focus': {
            outline: 'none'
        }
    }
}));

const phoneOptions = phoneFormatOptions.map((phone) => ({
    ...phone,
    label: phone.dial_code,
    value: phone.dial_code
}));

function PhoneFormat({ number, bold = false }) {
    const classes = useStyles({ bold });
    const [value, setValue] = React.useState('');
    const [format, setFormat] = React.useState(phoneOptions[0].format);
    const hidePhone = useHidePhone();

    React.useEffect(() => {
        if (number) {
            const numberFormat = addPlus(number);
            const phoneNumber = parsePhoneNumberFromString(numberFormat);
            const countryCode = phoneNumber?.countryCallingCode;

            if (countryCode) {
                const option = phoneOptions.find((opt) => opt.dial_code.includes(countryCode));
                if (option) {
                    const nationalNumber = phoneNumber.nationalNumber;

                    let nFormat = hidePhone ? option.nationalHideFormat : option.nationalFormat;
                    nFormat =
                        option === 'VN'
                            ? nationalNumber.length === 9
                                ? nFormat
                                : option.national11HideFormat
                            : nFormat;

                    setFormat(nFormat);
                    setValue(nationalNumber);
                }
            }
        }
    }, [number, hidePhone]);

    return (
        <Typography variant="body2" className={classes.input}>
            {formatPhone(value, format)}
        </Typography>
    );
}

export default React.memo(PhoneFormat);
