import React from 'react';
import { Grid, Divider, IconButton } from '@material-ui/core';
import { useInTurnStaffs } from 'hooks/turn';
import { useDispatch } from 'react-redux';
import * as turnActions from 'views/turn/actions';
// import ConfirmDialog from 'components/dialog/Confirm';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Tooltip from 'components/tooltip';
import { useIsHavingPermission } from 'hooks/auth';
import { MERCHANT_PERMISSIONS } from 'const';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import { enqueueSnackbar } from 'notifier/actions';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table, Column, defaultTableRowRenderer } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import ReactDOM from 'react-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import StaffInTurn from './Staff';

const SortableTable = SortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = SortableElement(defaultTableRowRenderer, { withRef: true });

const useStyles = makeStyles((theme) => ({
    staff: {
        position: 'relative',
        height: '100%'
    },
    staffWrapper: {},
    table: {
        '& .ReactVirtualized__Table__rowColumn': {
            height: '100%'
        }
    }
}));

function StaffInTurns() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const inTurnStaffs = useInTurnStaffs();
    // const [openConfirm, setOpenConfirm] = React.useState(false);
    const isAdvancedInternal = useIsHavingPermission(MERCHANT_PERMISSIONS.INTERNAL);

    const width = React.useMemo(() => {
        return isAdvancedInternal ? 70 : 0;
    }, [isAdvancedInternal]);

    const handleDragEnd = React.useCallback(
        async ({ oldIndex, newIndex }) => {
            if (newIndex === oldIndex) {
                return;
            }

            const dragId = inTurnStaffs?.[oldIndex]?.id;

            if (!dragId) return;

            let beforeStaffId = null;
            let afterStaffId = null;

            if (newIndex === 0) {
                beforeStaffId = inTurnStaffs?.[0]?.id;
            } else {
                let index = newIndex > oldIndex ? newIndex : Math.max(0, newIndex - 1);
                afterStaffId = inTurnStaffs?.[index]?.id;
            }

            dispatch(
                turnActions.reOrderStaffTurn({
                    afterStaffId,
                    beforeStaffId,
                    dragId,
                    dropIndex: newIndex,
                    errorCallback: (message) => {},
                    successCallback: () => {
                        console.log('reorder success');
                    }
                })
            );
        },
        [inTurnStaffs, dispatch]
    );

    const handleRefresh = React.useCallback(() => {
        dispatch(
            turnActions.getInTurnList({
                query: {
                    startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                },
                errorCallback: () => {
                    console.log('failed');
                },
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Refresh staff's turn success`
                        })
                    );
                }
            })
        );
    }, [dispatch]);

    const properties = [{ key: 'staff', headerName: 'Staff' }];

    const _cellRenderer1 = () => {
        let obj = {};
        properties.forEach((property) => {
            obj = {
                ...obj,
                [property.key]: ({ rowData }) => rowData[property.key]
            };
        });
        return obj;
    };

    const _cellRenderer = _cellRenderer1();

    const data = React.useMemo(() => {
        return inTurnStaffs?.map((staff, index) => {
            return {
                staff: <StaffInTurn staff={staff} index={index} />
            };
        });
    }, [inTurnStaffs]);

    const _rowGetter = React.useCallback(
        ({ index }) => {
            return data[index];
        },
        [data]
    );

    const _rowRenderer = ({ className, style, ...rest }) => {
        const isDisabled = rest?.rowData?.isDisabled;
        return (
            <SortableTableRowRenderer
                disabled={isDisabled}
                {...rest}
                className={`${className}`}
                style={{ ...style, zIndex: 9999, background: theme.colors.white }}
                key={rest.rowData.columnId}
            />
        );
    };

    return (
        <Grid wrap="nowrap" style={{ height: '100%', width }} container direction="column">
            <Grid item container direction="column" alignItems="center" justifyContent="center" style={{ height: 60 }}>
                <Grid item>
                    <Tooltip placement="left" title="Refresh staff's turn">
                        <IconButton size="small" onClick={handleRefresh}>
                            <RotateLeftIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item>
                <Divider
                    style={{
                        borderWidth: `0px 0px thin`,
                        borderColor: `rgba(145, 158, 171, 0.24)`,
                        borderStyle: 'dashed'
                    }}
                />
            </Grid>
            <Grid
                item
                style={{
                    height: `calc(100% - 70px)`,
                    maxHeight: `calc(100% - 70px)`
                    // overflowY: 'auto'
                }}
            >
                <AutoSizer>
                    {({ height, width }) => {
                        return (
                            <SortableTable
                                width={width}
                                height={height}
                                // eslint-disable-next-line react/no-find-dom-node
                                getContainer={(wrappedInstance) => ReactDOM.findDOMNode(wrappedInstance.Grid)}
                                rowRenderer={_rowRenderer}
                                rowGetter={_rowGetter}
                                rowHeight={56}
                                rowCount={data?.length}
                                data={data}
                                distance={1}
                                onSortEnd={handleDragEnd}
                                headerRowRenderer={() => null}
                                className={classes.table}
                            >
                                <Column
                                    width={width}
                                    dataKey="staff"
                                    flexGrow={1}
                                    cellRenderer={(props) => {
                                        return _cellRenderer?.staff(props);
                                    }}
                                />
                            </SortableTable>
                        );
                    }}
                </AutoSizer>
            </Grid>
        </Grid>
    );
}

export default StaffInTurns;
