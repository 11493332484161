import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { addPlus } from 'utils/phone';
import { supportLanguages } from 'utils/country';
import { useHidePhone } from 'hooks/app';
import { Typography } from '@material-ui/core';
import { formatPhone } from 'components/format-phone';

const useStyles = makeStyles(() => ({
    root: {},
    input: {
        width: '100%',
        height: '100%',
        border: 'none',
        padding: 0,
        fontSize: '0.875rem',
        fontFamily: `"Public Sans", sans-serif`,
        fontStyle: 'normal',
        background: 'transparent',
        display: `table-cell`,
        verticalAlign: 'inherit',
        color: `rgba(0, 0, 0, 0.87)`,
        lineHeight: `1.57143`,
        fontWeight: 400,
        '&:focus': {
            outline: 'none'
        }
    }
}));

const phoneOptions = supportLanguages.map((phone) => ({
    ...phone,
    label: `+ ${phone?.phone}`,
    value: `${phone.label}`
}));

function PhoneFormat({ phone }) {
    const classes = useStyles();
    const hidePhone = useHidePhone();

    const [value, format] = React.useMemo(() => {
        if (!phone) return [];

        const numberFormat = addPlus(phone);
        const phoneNumber = parsePhoneNumberFromString(numberFormat);
        const countryCode = phoneNumber?.countryCallingCode;

        if (!countryCode) return [];
        const option = phoneOptions.find((opt) => opt?.phone?.includes(countryCode));

        if (!option) return [];

        const nationalNumber = phoneNumber.nationalNumber;

        let nFormat = hidePhone ? option.nationalHideFormat : option.nationalFormat;
        nFormat = option === 'VN' ? (nationalNumber.length === 9 ? nFormat : option.national11HideFormat) : nFormat;

        return [nationalNumber, nFormat];
    }, [phone, hidePhone]);

    return (
        <Typography variant="body2" className={classes.input}>
            {formatPhone(value, format)}
        </Typography>
    );
}

export default React.memo(PhoneFormat);
