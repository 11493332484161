export function formatPhone(phone = '', format = '') {
    const formatList = format.split('');
    let idx = 0;
    let results = ``;

    // eslint-disable-next-line
    for (const [i, c] of formatList.entries()) {
        if (c === '-' || c === ' ' || c === '(' || c === ')') {
            results += c;
            continue;
        }

        if (c === '#') {
            const n = phone[idx];
            results += n;
        } else {
            results += c;
        }
        idx++;
    }
    return results;
}
